import React from "react"

import WebsiteLayout from '../../components/site/website_layout'
import BetaForm from '../../components/site/forms/beta_form'

export default () => (
  <WebsiteLayout>
    <BetaForm />
  </WebsiteLayout>
);
