import React from "react"

import Container from "react-bootstrap/Container"

import Banner from "../banner"
import routes from "../../../services/routes"

import { BetaForm } from "./discount_form"

export default () =>{

  return (
    <>
      <Banner title="Paid Beta" link={routes.form} />
      <Container style={{maxWidth:"700px"}}>
        <p style={{margin:"50px"}}>
          Thank you for your interest in joining our paid beta.
          Please fill out this form and we will be in touch shortly.
        </p>
        <BetaForm discount={false} />
      </Container>
    </>
  );
}
